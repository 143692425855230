var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zm-avatar avt"},[(_vm.addresses)?_vm._l((_vm.addresses),function(item,idx){return _c('span',{key:idx,staticClass:"item"},[(item.email && item.email.length > 0)?[_c('div',{staticClass:"user-avt-character",style:({
                     backgroundImage: 'url(' + _vm.urlVopsAvatar(item.email) + ')',
                     backgroundSize: 'contain'
                })})]:[_c('div',{staticClass:"user-avt-character",style:({
                    '--avatarBgColor': item.backgroundColor,
                    '--avatarColor': item.color,
                })},[_c('span',{staticClass:"avatar-short"},[_vm._v(_vm._s(item.firstCharacter))])])]],2)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }