import development from "@/config/develoment.json";
import production from "@/config/production.json";

let CONFIG = {}

switch (process.env.NODE_ENV) {
    case "production":
        CONFIG = Object.freeze(production);
        break;
    case "development":
        CONFIG = Object.freeze(development);
        break;
    default:
        CONFIG = Object.freeze(development);
        break;
}

export default CONFIG;