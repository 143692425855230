<template>
    <el-dialog
        custom-class="modal-filter__wrap"
        :title="titleDialog"
        :visible.sync="showModal"
        :close-on-click-modal="false"
        append-to-body
        @close="handleClose"
    >
        <div class="ds-js-al-bw-center">
            <base-input
                id="filter-name"
                :label="$t('zimbra.zhMsg.filterName')"
                placeholder=""
                type="text"
                :value="valueNameFilter"
                @input="handleChangeFilterName"
                autocomplete="off"
            />
            <base-checkbox
                :value="valueActiveFilter"
                :option="$t('zimbra.zhMsg.active')"
                @change="handleCheckActive"
            />
        </div>
        <div class="ds-js-al-st-center">
            <label for="">{{
                $t("zimbraNokey.zimbra_setting_filter_editFilter_title-dialog-1")
            }}</label>
            <base-select
                displayField="name"
                valueField="id"
                :value="valueType"
                :options="data.zSelectAnyOrAll"
                @change="handleChangezSelectAnyOrAll"
            />
            <label for="">{{
                $t("zimbraNokey.zimbra_setting_filter_editFilter_title-dialog-2")
            }}</label>
        </div>
        <base-filter-form
            :filterTests="filterTests"
            @handleAddFormFilter="handleAddFormFilter"
        />
        <label>{{ this.$t("zimbra.zhMsg.performTheFollowingActions") }}</label>
        <base-form-filter-action :filterActions="filterActions" />
        <div class="footer-edit-filter">
            <base-button-default
                class="btn-email default cover-size"
                @click="handleClose"
            >
                {{ this.$t("zimbra.zhMsg.actionTaskCancelTT") }}
            </base-button-default>
            <base-button-default
                class="btn-email primary cover-size"
                @click="handleSaveFilter"
            >
                {{ this.$t("zimbra.zhMsg.actionSave") }}
            </base-button-default>
        </div>
        <el-dialog
            :title="$t('zimbraNokey.zimbra_sidebar_criticalMsg')"
            :visible.sync="modalError"
            width="30%"
            append-to-body
        >
            <div class="form-wrap">
                <div class="icon-filter">
                    <i class="fas fa-exclamation-circle" />
                </div>
                <div class="msg-filter">
                    <div class="body">
                        <span class="content">{{ this.validateError }}</span>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <base-button-default
                    class="btn-email primary"
                    @click="modalError = false"
                >
                    OK
                </base-button-default>
            </span>
        </el-dialog>
    </el-dialog>
</template>

<script>
// import { v4 as uuidv4 } from "uuid";
import CommonUtils from "@/utils/common-utils";
import FilterService from "@/services/FilterService";
import {
    FILTER_ACTION_TYPE,
    FILTER_TEST_CONFIG,
    FILTER_TESTS,
} from "@/utils/constants";
import BaseFilterForm from "@/components/modules/base/base-filter-form";
import BaseFormFilterAction from "@/components/modules/base/base-form-filter-action";
import BaseInput from "@/components/modules/base/base-input";
import BaseButtonDefault from "@/components/modules/base/base-button/btn-default";
import BaseCheckbox from "@/components/modules/base/base-checkbox";
import BaseSelect from "@/components/modules/base/base-select";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
export default {
    props: {
        // titleDialog: {
        //     type: [String],
        //     default: "",
        // },
        // dialogFormEnabled: {
        //     type: Boolean,
        //     default: false,
        // },
        // action: {
        //     type: [String, Boolean],
        // },
        // actionType: {
        //     type: [Number, Boolean],
        // },
    },
    components: {
        BaseFormFilterAction,
        BaseInput,
        BaseButtonDefault,
        BaseCheckbox,
        BaseSelect,
        BaseFilterForm,
    },
    mixins: [CommonUtils],
    created() {
        // this.showModal = this.dialogFormEnabled;
    },
    mounted() {
        // alert('mounted ?????')
        this.$root.$on("hanldeCreateFilterRules", (data) => {
            this.newDataEdit = data
            this.keyCurrent = data?.name;
            this.filterTests = data?.filterTests;
            this.filterActions = data?.filterActions;
            this.valueNameFilter = data?.name;
            this.valueOldNameFilter = data?.name;
            this.valueActiveFilter = data?.active === 1 || data?.active ? true : false;
            this.valueType = data?.filterTests?.condition;
        });
        this.$root.$on("hanldeShowInfoFilter", (data) => {
            this.action = data?.action
            this.actionType = data?.actionType
            this.showModal = data?.dialogFormEnabled
            switch (this.action) {
                case FILTER_ACTION_TYPE.EDIT_FILTER:
                    this.titleDialog = this.$t("zimbra.zhMsg.editFilter")
                    break;
                case FILTER_ACTION_TYPE.ADD_FILTER:
                    this.titleDialog = this.$t('zimbra.zmMsg.createFilter')
                    break;
            }
        })
        this.$root.$on("handleUpdateFilterTests", (data) => {
            this.filterTests = data.length ? this.handleArrayToObject(data) : data;
        });
        this.$root.$on("handleChangeFilterActionItem", (data) => {
            this.filterActions = data;
        });
    },
    data() {
        return {
            FILTER_ACTION_TYPE: FILTER_ACTION_TYPE,
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            FILTER_TESTS: FILTER_TESTS,
            data: {
                zSelectAnyOrAll: [
                    {
                        id: FILTER_TEST_CONFIG.ANYOF,
                        name: this.$t("zimbra.zhMsg.EFILT_any"),
                    },
                    {
                        id: FILTER_TEST_CONFIG.ALLOF,
                        name: this.$t("zimbra.zhMsg.EFILT_all"),
                    },
                ],
            },
            titleDialog: "",
            dialogFormEnabled: false,
            action: "",
            actionType: "",
            filterRulesIncomingCurrent: [],
            filterRulesOutgoingCurrent: [],
            showModal: false,
            modalError: false,
            keyCurrent: null,
            validateError: "",
            valueNameFilter: "",
            valueActiveFilter: true,
            valueType: "",
            filterRulesCurrent: [],
            filterTests: {},
            filterActions: {},
            newDataEdit: undefined,
        };
    },
    watch: {
        dialogFormEnabled(val) {
            this.showModal = val;
        },
    },
    computed: {
        ...mapGetters(["filterRulesIncoming", "filterRulesOutgoing"]),
    },
    methods: {
        ...mapActions([
            "addFilterRulesIncoming",
            "editFilterRulesIncoming",
            "deleteFilterRuleIncoming",
            "addFilterRulesOutgoing",
            "deleteFIlterRuleOutgoing",
        ]),
        omit(obj, omitKey) {
            return Object.keys(obj).reduce((result, key) => {
                if (key !== omitKey) {
                    result[key] = obj[key];
                }
                return result;
            }, {});
        },
        handleChangeFilterName(val) {
            this.valueNameFilter = val;
        },
        handleCheckActive(val) {
            this.valueActiveFilter = val;
        },
        handleChangezSelectAnyOrAll(val) {
            this.valueType = val;
        },
        handleClose() {
            this.showModal = false;
            if (this.action === FILTER_ACTION_TYPE.EDIT_FILTER) {
                this.$root.$emit("handleSendNewDataEdit", this.newDataEdit);
            }
        },
        // handleCloseModel() {
        //     this.showModal = false;
        // },
        handleAddFormFilter() {
            const newFilterTest = Object.assign({}, this.filterTests);
            let listItem = [];
            const ignoreProps = "condition"
            for (let k in this.filterTests) {
                if (ignoreProps.indexOf(k) > -1) {
                    continue;
                }
                const item = this.filterTests[k];
                if (Array.isArray(item)) {
                    for (let i in item) {
                        item[i].types = k;
                        listItem.push(item[i]);
                    }
                } else {
                    item.types = k;
                    listItem.push(item);
                }
            }
            const listIndex = listItem.map(item => item.index)
            const indexItem = Math.max(...listIndex) + 1
            // debugger; // eslint-disable-line no-debugger
            const headerTest = {
                // index: uuidv4(),
                index: indexItem,
                header: "subject",
                stringComparison: "contains",
                value: "",
                types: "headerTest",
            };
            const list = [];
            for (let key in newFilterTest) {
                if (key === "headerTest" && newFilterTest["headerTest"]) {
                    if (newFilterTest[key].length) {
                        newFilterTest[key].forEach((item) => list.push(item));
                    } else {
                        list.push(newFilterTest[key]);
                    }
                }
            }
            if (list.length)
                newFilterTest["headerTest"] = list.concat([], headerTest);
            else newFilterTest["headerTest"] = Object.assign({}, headerTest);
            this.filterTests = _.cloneDeep(newFilterTest);
        },
        handleChangeFilterActionItem(data) {
            this.filterActions = data;
        },
        handleArrayToObject(data) {
            const _obj = {};
            data.forEach((item) => {
                let obj = this.omit(item, "types");
                if (_obj[item.types]) {
                    if (_obj[item.types].length) {
                        _obj[item.types].push(obj);
                    } else {
                        _obj[item.types] = [_obj[item.types], obj];
                    }
                } else {
                    _obj[item.types] = obj;
                }
            });
            return _obj;
        },
        isValidTimestamp(_timestamp) {
            const newTimestamp = new Date(_timestamp).getTime();
            return this.isNumeric(newTimestamp);
        },
        isNumeric(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        },
        handleValidateFilterRules() {
            let index = undefined
            switch (this.actionType) {
                case FILTER_ACTION_TYPE.INCOMING_FILTER: {
                    index = this.filterRulesIncoming.findIndex((item) => item.name == this.valueNameFilter && item.name != this.keyCurrent);
                    break;
                }
                case FILTER_ACTION_TYPE.OUTGOING_FILTER: {
                    index = this.filterRulesOutgoing.findIndex((item) => item.name == this.valueNameFilter && item.name != this.keyCurrent);
                    break;
                }
                default:
                    break;
            }
            const headerTest = this.filterTests[FILTER_TESTS.HEADER_TEST];
            const sizeTest = this.filterTests[FILTER_TESTS.SIZE_TEST];
            const dateTest = this.filterTests[FILTER_TESTS.DATE_TEST];
            const bodyTest = this.filterTests[FILTER_TESTS.BODY_TEST];
            const headerExitesTest =
                this.filterTests[FILTER_TESTS.HEADER_EXITS_TEST];
            let mesErr = "";
            if (this.valueNameFilter === "") {
                mesErr = this.$t("zimbra.zmMsg.filterErrorNoName");
            } else if (
                this.valueNameFilter && index > -1
            ) {
                mesErr = this.$t("zimbra.zmMsg.filterErrorNameExists");
            }
            else if (headerTest) {
                if (headerTest.length) {
                    const checkHeaderTest1 = headerTest.find(item => (item.header === "subject" && !item.value))
                    const checkHeaderTest2 = headerTest.find(item => (item.header !== "subject" && !item.value))
                    if (checkHeaderTest1) {
                        mesErr = this.$t("zimbra.zmMsg.filterErrorCondition", { 0: this.$t("zimbra.zhMsg.FILT_COND_HEADER_subject") });
                    } else if (checkHeaderTest2) {
                        mesErr = this.$t("zimbra.zmMsg.filterErrorCondition", { 0: this.$t("zimbra.zhMsg.EFILT_COND_HEADER_headerNamed") });
                    }
                }
                // else if (headerTest.header === "subject" && !headerTest.value) {
                //     mesErr = this.$t("zimbra.zmMsg.filterErrorCondition", { 0: this.$t("zimbra.zhMsg.FILT_COND_HEADER_subject") });
                // } else if (headerTest.header !== "subject" && !headerTest.value) {
                //     mesErr = this.$t("zimbra.zmMsg.filterErrorCondition", { 0: this.$t("zimbra.zhMsg.EFILT_COND_HEADER_headerNamed") });
                // }
            } else if (sizeTest) {
                if (sizeTest.length) {
                    const checkSizeTest = sizeTest.find(item => !item.s)
                    if (checkSizeTest) {
                        mesErr = this.$t("zimbra.zmMsg.filterErrorCondition", { 0: this.$t("zimbra.zhMsg.MO_size") });
                    }
                }
                else if (!sizeTest.s) {
                    mesErr = this.$t("zimbra.zmMsg.filterErrorCondition", { 0: this.$t("zimbra.zhMsg.MO_size") });
                }
            } else if (dateTest) {
                if (dateTest.length) {
                    const checkDateTest = dateTest.find(item => !item.d)
                    if (checkDateTest) {
                        mesErr = this.$t("zimbra.zmMsg.filterErrorCondition", { 0: this.$t("zimbra.zhMsg.calViewDayShort") });
                    }
                } else if (!dateTest.d) {
                    mesErr = this.$t("zimbra.zmMsg.filterErrorCondition", { 0: this.$t("zimbra.zhMsg.calViewDayShort") });
                }
            } else if (bodyTest) {
                if (bodyTest.length) {
                    const checkBody = bodyTest.find(item => !item.value)
                    if (checkBody) {
                        mesErr = this.$t("zimbra.zmMsg.filterErrorCondition", { 0: this.$t("zimbra.zmMsg.body") });
                    }
                } else if (!bodyTest.value) {
                    mesErr = this.$t("zimbra.zmMsg.filterErrorCondition", { 0: this.$t("zimbra.zmMsg.body") });
                }
            } else if (headerExitesTest) {
                if (headerExitesTest.length) {
                    const checkHeaderExitesTest = checkHeaderExitesTest.find(item => !item.header)
                    if (checkHeaderExitesTest) {
                        mesErr = this.$t("zimbra.zmMsg.filterErrorCondition", { 0: this.$t("zimbra.zhMsg.EFILT_COND_HEADER_headerNamed") });
                    }
                } else if (!headerExitesTest.header) {
                    mesErr = this.$t("zimbra.zmMsg.filterErrorCondition", { 0: this.$t("zimbra.zhMsg.EFILT_COND_HEADER_headerNamed") });
                }
            }
            this.validateError = mesErr;
            return mesErr;
        },
        beforeHandleSaveFilter() {
            const filterRules = [];
            let filterRule = [];
            const filterActions = this.filterActions.length
                ? this.handleArrayToObject(this.filterActions)
                : this.filterActions;
            const newFilterRuleItem = {};
            newFilterRuleItem["name"] = this.valueNameFilter;
            newFilterRuleItem["active"] = this.valueActiveFilter;
            newFilterRuleItem["filterTests"] = this.filterTests;
            newFilterRuleItem["filterTests"]["condition"] = this.valueType;
            newFilterRuleItem["filterActions"] = filterActions;
            switch (this.actionType) {
                case FILTER_ACTION_TYPE.INCOMING_FILTER: {
                    if (this.action === FILTER_ACTION_TYPE.ADD_FILTER) {
                        this.addFilterRulesIncoming(newFilterRuleItem);
                        filterRule = this.filterRulesIncoming
                    } else if (this.action === FILTER_ACTION_TYPE.EDIT_FILTER) {
                        this.newDataEdit = newFilterRuleItem;
                        filterRule = this.filterRulesIncoming.map((item) => {
                            if (item.name === this.keyCurrent) {
                                item = newFilterRuleItem;
                            }
                            return item;
                        });
                    }
                    break;
                }
                case FILTER_ACTION_TYPE.OUTGOING_FILTER: {
                    if (this.action === FILTER_ACTION_TYPE.ADD_FILTER) {
                        this.addFilterRulesOutgoing(newFilterRuleItem);
                    }
                    filterRule = this.filterRulesOutgoing.map((item) => {
                        if (item.name === this.keyCurrent) {
                            item = newFilterRuleItem;
                        }
                        return item;
                    });
                    break;
                }
                default:
                    break;
            }
            filterRules.push(filterRule);
            return filterRules;
        },
        handleSaveFilter() {
            if (!this.handleValidateFilterRules()) {
                this.filterRulesCurrent = this.beforeHandleSaveFilter();
                switch (this.actionType) {
                    case FILTER_ACTION_TYPE.INCOMING_FILTER: {
                        if (this.filterRulesCurrent.length) {
                            FilterService.modifyFilterRulesRequest(this.filterRulesCurrent[0])
                                .then((resp) => {
                                    const response =
                                        this.getResponseBody(resp)["ModifyFilterRulesResponse"];
                                    if (response) {
                                        this.handleClose();
                                        switch (this.action) {
                                            case FILTER_ACTION_TYPE.ADD_FILTER: {
                                                this.$root.$emit("handleSendActionFilter", this.actionType);
                                                this.$root.$emit("handleLoadDataResetActionAndType");
                                                break;
                                            }
                                            case FILTER_ACTION_TYPE.EDIT_FILTER: {
                                                this.$root.$emit("handleSendActionFilter", this.actionType);
                                                this.$root.$emit("handleSendNewDataEdit", this.newDataEdit);
                                                break;
                                            }
                                        }
                                        this.$notify({
                                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                                            message: "Lưu bộ lọc thành công",
                                            type: "success",
                                        });
                                    }
                                })
                                .catch(() => {
                                    if (
                                        this.action ===
                                        FILTER_ACTION_TYPE.ADD_FILTER
                                    ) {
                                        this.deleteFIlterRuleIncoming(
                                            this.keyCurrent
                                        );
                                    }
                                });
                        }
                        break;
                    }
                    case FILTER_ACTION_TYPE.OUTGOING_FILTER: {
                        if (this.filterRulesCurrent.length) {
                            FilterService.modifyOutgoingFilterRulesRequest(
                                this.filterRulesCurrent[0]
                            )
                                .then((resp) => {
                                    const response =
                                        this.getResponseBody(resp)[
                                        "ModifyOutgoingFilterRulesResponse"
                                        ];
                                    if (response) {
                                        this.handleClose();
                                        switch (this.action) {
                                            case FILTER_ACTION_TYPE.ADD_FILTER: {
                                                this.$root.$emit("handleLoadDataResetActionAndType");
                                                break;
                                            }
                                            case FILTER_ACTION_TYPE.EDIT_FILTER: {
                                                this.$root.$emit("handleSendNewDataEdit", this.newDataEdit);
                                                break;
                                            }
                                        }
                                        this.$notify({
                                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                                            message: this.$t("zimbra.zmMsg.filtersSaved"),
                                            type: "success",
                                        });
                                    }
                                })
                                .catch(() => {
                                    if (
                                        this.action ===
                                        FILTER_ACTION_TYPE.ADD_FILTER
                                    ) {
                                        this.deleteFIlterRuleOutgoing(
                                            this.keyCurrent
                                        );
                                    }
                                    // this.$notify({
                                    //     title: "Thất bại",
                                    //     message: "Lưu bộ lọc thất bại",
                                    //     type: "error",
                                    // });
                                });
                        }
                        break;
                    }
                    default:
                        this.handleClose();
                        break;
                }
            } else {
                // this.modalError = true;
                this.$confirm(
                    this.validateError,
                    this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                    {
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        showCancelButton: false,
                        type: "error",
                    }
                );
            }
        },
    },
};
</script>

<style></style>
