import BaseService from "../../shared/services/BaseService";
import axios from "axios";
import CONFIG from '@/config/config';
import CommonUtils from "../../utils/common-utils";
import { ZmContact } from "../../utils/constants";
import _ from "lodash";
class ContactService extends BaseService {
    URL_UPLOAD = CONFIG.ENDPOINT["uploadService"];
    URL_FILE = CONFIG.ENDPOINT["serviceContent"];
    zimbraHome = CONFIG.ENDPOINT["importExportService"]
    zimbraCompile = CONFIG.ENDPOINT["compileService"]
    constructor() {
        super("zimbraService")
    }
    escape(string) {
        return _.escape(string.toString())
    }
    getInfo() {
        const store = localStorage.getItem('zimbra_token');
        if (store != null) {
            const zimbraToken = JSON.parse(store);
            return zimbraToken || null;
        }
        return '';
    }
    async uploadImage(formData) {
        const url = `${this.URL_UPLOAD}?lbfums=`;
        // document.cookie = `ZM_AUTH_TOKEN=${this.getAuthToken()}`;
        const config = {
            baseURL: url,
            timeout: CONFIG.TIMEOUT_REQUEST,
            headers: {
                "X-Gravitee-Api-Key": CONFIG.X_GRAVITEE_API_KEY_SERVICE_UPLOAD,
                "X-Cookie": `ZM_AUTH_TOKEN=${this.getAuthToken()}`,
                "Access-Control-Allow-Credentials": true,
                "X-Requested-With": "XMLHttpRequest",
                "Cache-Control": "no-cache",
                "content-type": "multipart/form-data"
            },
            validateStatus: function (status) {
                return status >= 200 && status < 500; // default
            },
            withCredentials: true,
            // exposedHeaders: ["set-cookie"],
        }
        const instanceService = axios.create(config);
        return await instanceService.post(url, formData, config);
    }
    async getImageByContact(formData) {
        const url = `${CONFIG.ENDPOINT["homeService"]}/~/?auth=co&id=${formData.id}&part=1&max_width=48&max_height=48&t=${new Date().getTime()}`;
        // document.cookie = `ZM_AUTH_TOKEN=${this.getInfo() ? this.getInfo().auth_token : ""}`;
        const config = {
            baseURL: url,
            timeout: CONFIG.TIMEOUT_REQUEST,
            headers: {
                "Accept": "image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8",
                "X-Cookie": `ZM_AUTH_TOKEN=${this.getAuthToken()}`,
                "Cache-Control": "no-cache",
            },
            responseType: 'blob',
            validateStatus: function (status) {
                return status >= 200 && status < 500; // default
            },
        }
        const instanceService = axios.create(config);
        return await instanceService.get(url, config);
    }
    async getFileRequest(formData) {
        const url = `${this.URL_FILE}?aid=${formData.aid}`;
        const config = {
            baseURL: url,
            timeout: CONFIG.TIMEOUT_REQUEST,
            headers: {
                "Accept": "image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8",
                "X-Gravitee-Api-Key": CONFIG.X_GRAVITEE_API_KEY_SERVICE_CONTENT,
                "X-Cookie": `ZM_AUTH_TOKEN=${this.getAuthToken()}`,
                "Cache-Control": "no-cache",
            },
            responseType: 'blob',
            validateStatus: function (status) {
                return status >= 200 && status < 500; // default
            },
        }
        const instanceService = axios.create(config);
        return await instanceService.get(url, config);
    }
    async getContactGroup(username) {
        const params = CommonUtils.methods.queryStringSet(ZmContact.URL_ARGS, true)
        const restUrl = [
            `${this.zimbraHome}/`,
            (username || this.getInfo().username.toLowerCase()),
            ZmContact.URL,
        ].join("").concat(params)
        const config = {
            baseURL: restUrl,
            headers: {
                "Access-Control-Allow-Credentials": true,
                "X-Requested-With": "XMLHttpRequest",
                "Cache-Control": "no-cache",
                "X-Cookie": `ZM_AUTH_TOKEN=${this.getAuthToken()}`,
            },
            responseType: 'blob',
            validateStatus: function (status) {
                return status >= 200 && status < 500; // default
            },
            withCredentials: true,
            // exposedHeaders: ["set-cookie"],
        }
        const instanceService = axios.create(config);
        return await instanceService.get(restUrl, config);
    }
    async getContactsRequest(data) {
        const url = `${this.baseURL}/getContactsRequest`
        const formData = {
            derefGroup: "1",
            returnCertInfo: "1",
            l: `${data[0].l}`,
            cn: data.map(attr => ({ cn: attr.id.toString() }))
        }
        return await this.postRequest(url, formData, true, false)
    }
    async contactActionRequest(formData) {
        const url = `${CONFIG.ENDPOINT["zimbraService"]}/contactActionRequest`
        return this.postRequest(url, formData)
    }
    buildListCN(data) {
        let _str = ""
        data.forEach(item => {
            if (item && item.content !== undefined) {
                if (item.n === "image") {
                    _str += `<urn1:a n="${item.n}" aid="${item.aid}" />`
                } else {
                    _str += `<urn1:a n="${item.n}">${this.escape(item.content)}</urn1:a>`
                }

            }
        })
        return _str
    }
    buildListM(data) {
        let _str = ""
        data.forEach(item => {
            if (item && item.value && item.op) {
                _str += `<urn1:m type="${item.type}" value="${this.escape(item.value)}" op="${item.op}"/>`
            } else {
                _str += `<urn1:m type="${item.type}" value="${this.escape(item.value)}"/>`
            }
        })
        return _str
    }
    async createContactRequest(formData) {
        const url = `${this.baseURL}/createContactRequest`;
        const xmlStr = `<urn1:cn l="${formData.l}">${this.buildListCN(formData.a)}${this.buildListM(formData.m)}</urn1:cn>`
        return await this.postRequest(url, {
            xml: xmlStr
        });
    }
    async assginTagActionRequest(form) {
        const url = `${this.baseURL}/contactActionRequest`;
        return this.postRequest(url, form);
    }
    async modifyContactRequest(formData) {
        const url = `${this.baseURL}/modifyContactRequest`;
        let xmlStr = `<urn1:cn id="${formData.id}">${this.buildListCN(formData.a)}${this.buildListM(formData.m)}</urn1:cn>`
        if (formData.tn !== undefined && formData.tn !== null && formData.tn !== "") {
            xmlStr = `<urn1:cn id="${formData.id}" tn="${formData.tn}">${this.buildListCN(formData.a)}${this.buildListM(formData.m)}</urn1:cn>`
        }
        return await this.postRequest(url, {
            xml: xmlStr
        }, true, false);
    }
    async getAccountDistributionListsRequest(formData) {
        const url = `${this.baseURL}/getAccountDistributionListsRequest`;
        return await this.postRequest(url, formData)
    }
    async getDistributionListRequest(formData) {
        const url = `${this.baseURL}/getDistributionListRequest`;
        return await this.postRequest(url, formData)
    }
    async getDistributionListMembersRequest(formData) {
        const url = `${this.baseURL}/getDistributionListMembersRequest`;
        return await this.postRequest(url, formData)
    }
    async bathRequest(listRequest) {
        return await this.postRequest(this.zimbraCompile, { listRequest: listRequest })
    }
}
export default new ContactService();
