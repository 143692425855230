
<template>
        <!-- v-if="formShare.optionShare == 'usr'" -->
    <div>
        <el-select
            class="w-100"
            ref="refSelectEmail"
            :id="`address_selector_${generateUniqueId()}`"
            :size="size"
            :disabled="disabled"
            :multiple-limit="multipleLimit"
            v-model="dataSelected"
            multiple
            filterable
            remote
            :loading="loading"
            :placeholder="placeholder"
            :remote-method="remoteMethodEmail"
            :loading-text="loaddingText"
            @change="onchange"
            value-key="email"
        >
            <el-option
                v-for="item in listEmail"
                :key="`${item.value}_${generateUniqueId()}`"
                :label="item.value"
                v-bind:isGroup="item.isGroup"
                :value="item.value"
            >
                <ItemAddressSelector
                    :value="item"
                    :fieldLabel="'label'"
                    :multiple="multiple"
                    @selectMembers="selectMembers"
                    @selectAllMembers="selectAllMembers"
                    @closed="
                        listEmail = [];
                        $refs.refSelectEmail.blur();
                    "
                />
            </el-option>
        </el-select>
        <context-menu
                id="context-menu"
                :class="`${showMenuAction ? '' : 'item-group-mail' }`"
                ref="ctxMenu"
                @ctx-open="onCtxOpen"
                @ctx-close="onCtxClose"
                @ctx-cancel="onCtxClose"
            >
            <div class="right-menu-mail box-control-inbox address-menu">
                <ul v-if="showMenuAction">
                    <li>
                        <a class="menu-item" href="javascript:;" @click="handleAddressAction(ADDRESS_ACTION_TYPE.DELETE)">
                            <i class="ic el-icon-circle-close" style="color: red;height: unset "></i>
                            <span>{{ $t("zimbra.zhMsg.actionDelete") }}</span>
                        </a>
                    </li>
                    <li>
                        <a class="menu-item" href="javascript:;" @click="handleAddressAction(ADDRESS_ACTION_TYPE.COPY)">
                            <i class="ic ic-folder-copy" style="margin-left: -5px;margin-right: 10px;"></i>
                            <span>{{ $t("zimbra.zmMsg.copy") }}</span>
                        </a>
                    </li>
                    <li>
                        <a class="menu-item" href="javascript:;" @click="handleAddressAction(ADDRESS_ACTION_TYPE.EDIT)">
                            <i class="ic fi-rr-pencil" ></i>
                            <span>{{ $t("zimbra.zhMsg.edit") }}</span>
                        </a>
                    </li>
                    <li>
                        <a class="menu-item" :class="`${this.tagActionIsGroup ? '' : 'noclick'}`" href="javascript:;" @click="handleAddressAction(ADDRESS_ACTION_TYPE.EXTEND)">
                            <i class="ic el-icon-rank" style="height: unset"></i>
                            <span>{{ $t("zimbra.zmMsg.expand") }}</span>
                        </a>
                    </li>
                    <li>
                        <a class="menu-item" :class="`${this.tagActionIsGroup ? 'noclick' : ''}`" href="javascript:;" @click="handleAddressAction(ADDRESS_ACTION_TYPE.EDIT_CONTACT)">
                            <i class="ic fi-rr-pencil" ></i>
                            <span>{{ isAddContact? $t("zimbra.zhMsg.AB_ADD_CONTACT"): $t("zimbra.zhMsg.AB_EDIT_CONTACT") }}</span>
                        </a>
                    </li>
                </ul>
                <perfect-scrollbar style="max-height: 400px" v-if="!showMenuAction">
                    <el-menu ref="rightMenu" class="menu-list-members">
                        <el-menu-item
                            v-if="multiple"
                            class="menu-item"
                            @click="selectAllMembers(listMembers)"
                        >
                            <span>{{
                                $t("zimbraNokey.zimbra_sidebar_dialogShare_selectAll", {
                                    total: listMembers.length,
                                })
                            }}</span>
                        </el-menu-item>
                        <el-menu-item
                            v-for="item in listMembers"
                            :key="item"
                            @click="selectMembers(item)"
                            class="menu-item"
                        >
                            <i class="fas fa-user-tag"></i>
                            <span class="pl-2">{{ item }}</span>
                        </el-menu-item>
                    </el-menu>
                </perfect-scrollbar>
            </div>
        </context-menu>
        <base-address-popover ref="addressPopover" v-if="showPopover" :value="objectHover" :hiddenDisplayName="true">
        </base-address-popover>
    </div>
</template>
<script>
import BaseAddressPopover from "@/components/modules/base/base-address-popover/BaseAddressPopover";
import contextMenu from "vue-context-menu";
import CommonUtils from "@/utils/common-utils";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import ItemAddressSelector from "./ItemAddressSelector.vue";
import { mapGetters } from "vuex";
import { ADDRESS_ACTION_TYPE } from "@/utils/constants";
import _ from "lodash";
const $ = window.jQuery;
// import $ from 'jquery'
export default {
    name: "BaseAddressSelector",
    mixins: [CommonUtils],
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: {
            type: Array,
            default() {
                return []
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: "medium"
        },
        multipleLimit: {
            type: Number,
            default: 0
        },
        placeholder: {
            type: String,
            default() {
                return this.$t('zimbraNokey.zimbra_sidebar_dialogShare_emailPlaceholder')
            }
        },
        isFocus: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        ...mapGetters([
            "listFileContacts",
            "preference"
        ]),
        isAddContact() {
            if (this.tagAction) {
                return this.isAddNewContact(this.tagAction.textContent)
            }
            return false;
        }
    },
    components: {
        ItemAddressSelector,
        contextMenu,
        BaseAddressPopover
    },
    data() {
        return {
            ADDRESS_ACTION_TYPE: ADDRESS_ACTION_TYPE,
            dataSelected: this.value,
            listEmail: [],
            loaddingText: this.$t("zimbra.zmMsg.autocompleteWaiting"),
            loading: false,
            tagAction: null,
            spamNodeTag : null,
            mapValueWithIsGroup: new Map(),
            tagActionIsGroup: false,
            showMenuAction: true,
            listMembers: [],
            eventAction: null,
            objectHover: {},
            showPopover: false,
            listEmailPermanent: [],
            mapValueWithObject: new Map(),
        };
    },
    watch: {
        value(val) {
            this.dataSelected = val;
        },
        listEmail(val) {
            if (val && val.length > 0) {
                this.listEmailPermanent = _.cloneDeep(val);
                val.forEach(x => {
                    this.mapValueWithIsGroup.set(x.value, x.isGroup);
                    this.mapValueWithObject.set(x.value, x);
                })
            }
        }
    },
    mounted() {
        //Sangbx comment vì ảnh hưởng 1 chút bên setting - lịch
        // setTimeout(() => {
        //     this.$refs.refSelectEmail.focus();
        // }, 100);
        //Check xem có sử dụng focus hay không
        if(this.isFocus){
            setTimeout(() => {
                this.$refs.refSelectEmail.focus();
            }, 100);
        }
    },
    methods: {
        onchange() {
            const indexGroupEmail =  this.dataSelected.findIndex( x => x.indexOf(';') > -1);
            if (indexGroupEmail > -1) {
                const groupEmail = this.dataSelected[indexGroupEmail].split(';');
                this.dataSelected.splice(indexGroupEmail);
                this.dataSelected = this.dataSelected.concat(groupEmail);
            }
            this.$emit("change", this.dataSelected);
            this.$refs.refSelectEmail.$el.querySelector('.el-select__input.is-medium').focus();
            this.$refs.refSelectEmail.$el.addEventListener("contextmenu", this.menuAction);
            setTimeout(() => {
                $('.el-select__tags-text').bind('mouseover', (e) => {
                    setTimeout(() => {
                        this.handleShowAddressPopover(e);
                    }, 1000)
                });
                $('.el-select__tags-text').bind('mouseout', () => {
                    setTimeout(() => {
                        if (!$(`.zm-address-info-popover`).is(":hover")) {
                            this.showPopover = false;
                        }
                    }, 200)
                });
            }, 100)
        },
        handleShowAddressPopover(event) {
            this.showPopover = true;
            const textHover = event.currentTarget.innerText;
            this.objectHover = this.mapValueWithObject.get(textHover);
            this.objectHover.a = textHover;
            setTimeout(() => {
                this.$refs.addressPopover.onShowPopover(event);
            }, 200)
        },
        menuAction(event) {
            this.eventAction = event;
            if (event.target._prevClass == "el-select__tags-text") {
                event.preventDefault();
                if (this.tagAction && this.tagAction.classList) {
                    this.tagAction.classList.remove("tag-action");
                }
                this.showMenuAction = true;
                this.tagAction = event.target.parentElement
                setTimeout(() => {
                    event.target.parentElement.classList.add("tag-action");
                }, 1)
                // $(this.tagAction)[0].classList.add("tag-action")
                this.$refs.ctxMenu.open(event, {
                    source: this.tagAction.textContent,
                });
            }
        },
        onCtxClose() {
            this.tagAction.classList.remove("tag-action");
        },
        onCtxOpen(locals) {
            this.tagActionIsGroup = this.mapValueWithIsGroup.get(locals.source)
        },
        handleAddressAction(actionType) {
            switch (actionType) {
                case ADDRESS_ACTION_TYPE.DELETE:
                    if (this.tagAction && this.tagAction.textContent) {
                        this.dataSelected = this.dataSelected.filter(x => x != this.tagAction.textContent);
                        this.$refs.refSelectEmail.$el.childNodes[0].childNodes[1].removeChild(this.tagAction);
                        this.$emit("change", this.dataSelected);
                    }
                    break
                case ADDRESS_ACTION_TYPE.COPY:
                    this.copyToClipboard(this.tagAction.textContent);
                    break
                case ADDRESS_ACTION_TYPE.EDIT: {
                    const inputNode = document.createElement("input");
                    inputNode.value = this.tagAction.textContent;
                    inputNode.id = "idInputEdit";
                    inputNode.className = "input-edit";
                    this.spamNodeTag = this.tagAction.childNodes[0].childNodes[0];
                    this.tagAction.childNodes[0].removeChild(this.tagAction.childNodes[0].childNodes[0]);
                    inputNode.blur(function () {
                        this.tagAction.removeChild(inputNode);
                    })
                    this.tagAction.childNodes[0].appendChild(inputNode);
                    setTimeout(() => {
                        inputNode.focus();
                        inputNode.addEventListener('blur', this.handleEditTag)
                    }, 200)
                    break
                }
                case ADDRESS_ACTION_TYPE.EXTEND:
                    this.openListMembers();
                    this.showMenuAction = false;
                    this.$refs.ctxMenu.open(this.eventAction, {
                        source: this.tagAction.textContent,
                    });
                    break
                case ADDRESS_ACTION_TYPE.EDIT_CONTACT: {
                    const _currentActiveMail = {
                        from: [{
                            a: this.tagAction.textContent,
                            email: this.tagAction.textContent,
                            text: this.tagAction.textContent
                        }]
                    };
                    const path = "/mail/contacts";
                    this.addLocalStorageObject("editContactDataForm", {_currentActiveMail: _currentActiveMail, isEdit: !this.isAddContact})
                    if (this.$route.path !== path) {
                        this.$router.push(path)
                    } else {
                        this.$root.$emit("editMailContact");
                    }
                    // window.location.href = "/mail/contacts";
                    break
                }
            }
        },
        handleEditTag() {
            const inputNode = document.getElementById("idInputEdit");
            var idx = this.dataSelected.indexOf(this.spamNodeTag.textContent);
            this.dataSelected[idx] = inputNode.value;
            this.tagAction.childNodes[0].removeChild(inputNode);
            this.spamNodeTag.textContent = inputNode.value;
            this.tagAction.childNodes[0].appendChild(this.spamNodeTag);
            
        },
        openListMembers() {
            const content = this.tagAction.textContent
            const form = { dl: { content: `${content}` }, limit: 100, offset: 0 };
            ZimbraMailService.getDistributionListMembersRequest(form)
                .then((res) => {
                    const data =
                        this.getResponseBody(res)[
                            "GetDistributionListMembersResponse"
                        ];
                    if (data && data.dlm) {
                        this.listMembers = Array.isArray(data.dlm)
                            ? data.dlm
                            : [data.dlm];
                    }
                })
                .catch();
        },
        selectMembers(item) {
            if (this.multiple) {
                this.dataSelected = this.dataSelected ? this.dataSelected : [];
                this.dataSelected.push(item);
                this.$emit("change", this.dataSelected);
            } else {
                this.dataSelected = item;
                this.$emit("change", this.dataSelected);
            }
            this.handleAddressAction(ADDRESS_ACTION_TYPE.DELETE)
        },
        selectAllMembers(listMembers) {
            this.dataSelected = this.dataSelected ? this.dataSelected : [];
            this.dataSelected = [ ...listMembers.filter(x => !this.dataSelected.includes(x)), ...this.dataSelected];
            this.handleAddressAction(ADDRESS_ACTION_TYPE.DELETE)
            this.$emit("change", this.dataSelected);
        },
        getResponseBodyEmail(response) {
            return response["data"]["soap:Envelope"]["soap:Body"][
                "AutoCompleteResponse"
            ]["match"];
        },
        remoteMethodEmail(query) {
            if(query.indexOf(",") > -1 && this.preference.zimbraPrefAutoCompleteQuickCompletionOnComma && this.listEmail && this.listEmail.length > 0) {
                this.dataSelected = this.dataSelected.concat(this.listEmail[0].value);
                return;
            }
            if (query !== "") {
                const form = { name: `${query}` };
                this.loading = true;
                ZimbraMailService.autoCompleteRequest(form)
                    .then((res) => {
                        const data = this.getResponseBodyEmail(res);
                        this.listEmail = data;
                        if (this.listEmail) {
                            this.listEmail = Array.isArray(this.listEmail) ? this.listEmail : [this.listEmail];
                            this.listEmail.forEach((x) => {
                                const emailContact = this.listFileContacts.find(e => e.id == x.id);
                                x.display = x.display || x.email || x.nickname;
                                x.value = this.getAddressEmailFromText(x.display) || x.display;
                                x.label = x.display;
                                x.email = x.value;
                                x.showExpand = true;
                                if (emailContact) {
                                    if (emailContact.type == "group") {
                                        x.showExpand = false;
                                        let listMailGroup = [];
                                        emailContact.m.map(e => e.cn.a).forEach(e => listMailGroup = listMailGroup.concat(e));
                                        listMailGroup = listMailGroup.filter(e => e.n == "email");
                                        x.value = listMailGroup.map(x => x.content).join(';');
                                    }
                                    x.email = emailContact
                                    if (emailContact.email) {
                                        const namePrefix = emailContact.namePrefix || "";
                                        const firstName = emailContact.firstName || "";
                                        const middleName = emailContact.middleName || "";
                                        const maidenName = _.isNil(emailContact.maidenName) ? "" : `(${emailContact.maidenName})`;
                                        const lastName = emailContact.lastName || "";
                                        const nameSuffix = emailContact.nameSuffix || "";
                                        x.label = [namePrefix, firstName, middleName, maidenName, lastName, nameSuffix].join(' ').trim().replace('  ', '');
                                        x.label = `"${x.label}" <${emailContact.email}>`
                                    } else {
                                        x.label = emailContact.nickname
                                    }
                                }
                            });
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.listEmail = [];
                        this.loading = false;
                    });
            } else {
                this.listEmail = [];
            }
        },
    }
};
</script>