<template>
    <div>
    </div>
</template>

<script>
import CommonUtils from "@/utils/common-utils";
import AuthSSOService from "@/services/AuthSSOService.js";
import AccountService from "@/services/account/AccountService";
// import _ from "lodash";
import { mapActions } from "vuex";
export default {
    name: "AuthSSO",
    mixins: [CommonUtils],
    data() {
        return {

        };
    },
    created() {
    },
    mounted() {
        const query = this.$route.query;
        const ticket = query.ticket;
        const origin = process.env.NODE_ENV === "development" ? "mail.epass-vdtc.com.vn" : window.location.origin;

        console.log(ticket, this.decryptSSOToken(ticket))


        const formData = {
            // password: this.data.password,
            ticket : ticket
        }
        const configDomain = this.$configZimbra.VIRTUAL_HOST.find(el => origin.indexOf(el.origin) !== -1);
        let suffixesDomain = "";
        if (configDomain) {
            formData['virtualHost'] = configDomain.origin;
            //formData['ticket'] = ticket;
            suffixesDomain = configDomain.domain;
        }
        // formData['username'] = this.data.username.indexOf("@") > -1 ? this.data.username : this.data.username.concat(suffixesDomain);

        // formData['username'] = 'quangnv_is@bitel.com.pe';
        // formData['password'] = '1qazXSW@'
        AccountService.loginSSO(formData)
            .then(async (response) => {
                const { authToken, lifetime, session } = this.getResponseBody(response)["AuthResponse"];
               // const _username = formData.username;
                const tokenStorage = {
                    lifetime: lifetime,
                   // username: _username,
                    session: session
                };
                
                tokenStorage['auth_token'] = authToken;
                this.setCookie("ZM_AUTH_TOKEN", authToken);
                
                this.addLocalStorageObject("zimbra_token", tokenStorage);
                // this.setCookie("_session" , session?.id);
                // this.setCookie("X-Gravitee-Api-Key", this.$configZimbra.X_GRAVITEE_API_KEY_SERVICE_IMPORT_EXPORT);
                this.getAccountInfo(_username);
                const { origin } = window.location;
                window.location.href = `${origin}/mail`;
            })
            .catch((err) => {
                const errorCode = this.getResponseBody(err.response)[
                    "soap:Fault"
                ].detail.Error.Code;
                if (errorCode == ERROR_CODE.ACCOUNT_AUTH_FAILED) {
                    this.authFailed = true;
                }
            });
    },
    methods: {
        ...mapActions(["getAccountInfo"]),
    },
};
</script>

<style></style>
