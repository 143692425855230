import AccountService from "@/services/settings/AccountService";
import accountService from "@/services/account/AccountService"
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import { ZmSetting, ZmAccount } from "@/utils/constants";
import i18n from "@/plugins/i18n";
import { v4 as uuidv4 } from "uuid";
const accountModule = {
    state: {
        viewMode: ZmAccount.VIEW_ACCOUNT,
        accountCurrent: null,
        _fromAddress: [],
        infoResponse: {},
        externalAccount: [],
        identites: [],
        listPersonal: [],
        primaryAccount: {},
        zimbraMailAlias: [],
        displayName: {
            name: "displayName",
            content: ""
        },
        forwardReplySignatureId: {
            name: "",
            content: ""
        },
        whenSentToEnabled: {
            name: "",
            content: ""
        },
        whenInFoldersEnabled: {
            name: "",
            content: ""
        },
        fromAddressType: {
            name: "",
            content: ""
        },
        fromAddress: {
            name: "",
            content: ""
        },
        mailSignature: {
            name: "",
            content: ""
        },
        fromDisplay: {
            name: "",
            content: ""
        },
        sentMailFolder: {
            name: "",
            content: ""
        },
        saveToSent: {
            name: "",
            content: ""
        },
        identityId: {
            name: "",
            content: ""
        },
        replyToAddress: {
            name: "",
            content: ""
        },
        mailSignatureStyle: {
            name: "",
            content: ""
        },
        replyToDisplay: {
            name: "",
            content: ""
        },
        forwardReplyFormat: {
            name: "",
            content: ""
        },
        defaultSignatureId: {
            name: "",
            content: ""
        },
        replyIncludeOriginalText: {
            name: "",
            content: ""
        },
        replyToEnabled: {
            name: "",
            content: ""
        },
        identityName: {
            name: "",
            content: ""
        }
    },
    getters: {
        accountCurrent: state => state.accountCurrent,
        viewMode: state => state.viewMode,
        _fromAddress: state => state._fromAddress,
        identites: state => state.identites,
        zimbraMailAlias: state => state.zimbraMailAlias,
        externalAccount: state => state.externalAccount,
        listPersonal: state => state.listPersonal,
        primaryAccount: state => state.primaryAccount,
        infoResponse: state => state.infoResponse,
        displayName: state => state.displayName
    },
    mutations: {
        SET_ACCOUNT_CURRENT(state, data) {
            state.accountCurrent = data
        },
        SET_VIEW_MODE(state, data) {
            state.viewMode = data
        },
        SET_FROM__ADDRESS(state, data) {
            state._fromAddress = data
        },
        SET_INFORESPONSE(state, data) {
            state.infoResponse = data
        },
        SET_DISPLAYNAME(state, data) {
            state.displayName.content = data
        },
        SET_ALL_IDENTITIES(state, data) {
            state.identites = data
        },
        SET_LIST_PERSONAL(state, data) {
            state.listPersonal = data
        },
        SET_PRIMARY_ACCOUNT(state, data) {
            state.primaryAccount = data
        },
        EDIT_PRIMARY_ACCOUNT(state, data) {
            state.primaryAccount.a = data
        },
        ADD_PERSONAL(state, data) {
            state.listPersonal.push(data)
        },
        EDIT_PERSONAL(state, data) {
            let _index = state.listPersonal.findIndex(
                item => item.id === data.zimbraPrefIdentityId
            );
            let _personal = state.listPersonal[_index];
            const _a = [];
            for (let key in data) {
                if (key === "zimbraPrefIdentityName") _personal.name = data[key]
                _a.push({
                    name: key,
                    content: data[key]
                });
            }
            _personal.a = _a;
            state.listPersonal = [
                ...state.listPersonal.slice(0, _index),
                _personal,
                ...state.listPersonal.slice(_index + 1)
            ];
        },
        DELETE_PERSONAL(state, data) {
            state.listPersonal = state.listPersonal.filter(
                personal => personal.id !== data.id
            );
        },
        SET_EXTERNAL_ACCOUNT(state, data) {
            state.externalAccount = data
        },
        ADD_EXTERNAL(state, data) {
            state.externalAccount.push(data)
        },
        EDIT_EXTERNAL(state, data) {
            const _index = state.externalAccount.findIndex(ele => ele.id === data.id)
            state.externalAccount = [
                ...state.externalAccount.slice(0, _index),
                data,
                ...state.externalAccount.slice(_index + 1),
            ]
        },
        DELETE_EXTERNAL(state, data) {
            state.externalAccount = state.externalAccount.filter(external => external.id !== data.id)
        }
    },
    actions: {
        async getInfoRequest({ commit }) {
            try {
                let response = await AccountService.getInfoRequest();
                response =
                    response["data"]["soap:Envelope"]["soap:Body"][
                    "GetInfoResponse"
                    ];
                commit("SET_INFORESPONSE", response);
                const _listAtrr = response?.attrs?.attr;
                if (_listAtrr.length) {
                    const displayName = _listAtrr.find(
                        item => item.name === "displayName"
                    )?.content;
                    commit("SET_DISPLAYNAME", displayName);
                }
                const _listIdentity = response?.identities?.identity;
                const listAccount = [];
                if (_listIdentity) {
                    if (_listIdentity.length > 0) {
                        _listIdentity.map(item => listAccount.push(item));
                    } else {
                        listAccount.push(_listIdentity);
                    }
                }
                const listPersonal = [];
                listAccount.forEach(item => {
                    if (item.name !== "DEFAULT")
                        listPersonal.push({
                            ...item,
                            email: response.name
                        });
                });
                const primaryAccount = listAccount.find(
                    item => item.name === "DEFAULT"
                );
                const zimbraToken = CommonUtils.methods.getLocalStorageObject("zimbra_token")
                const info = await accountService.getAccountInfoRequest({
                    username: zimbraToken.username
                });
                const { name, attr } = CommonUtils.methods.getResponseBody(info)['GetAccountInfoResponse'];
                const formData = {
                    rights: [
                        { right: ZmSetting.SEND_AS },
                        { right: ZmSetting.SEND_ON_BEHALF_OF },
                    ]
                };
                const discoverRights = await ZimbraMailService.discoverRightsRequest(formData);
                const { targets } = CommonUtils.methods.getResponseBody(discoverRights)['DiscoverRightsResponse'];
                const _address = [
                    {
                        right: ZmSetting.SEND_AS,
                        id: attr.find(el => el.name === "zimbraId")?.content,
                        d: attr.find(el => el.name === "displayName")?.content,
                        a: primaryAccount.a,
                        address: name,
                        label: name,
                        uuid: uuidv4(),
                    }
                ]
                if (targets) {
                    const _list = Array.isArray(targets) ? targets : [targets];
                    _list.forEach((taget) => {
                        if (Array.isArray(taget.target)) {
                            taget.target.forEach((ele) => {
                                _address.push({
                                    right: taget.right,
                                    d: ele.d,
                                    type: ele.type,
                                    address: ele.email.addr,
                                    label: taget.right === ZmSetting.SEND_ON_BEHALF_OF ?
                                        `${i18n.t('zimbra.zmMsg.onBehalfOfMidLabel')} ${ele.email.addr}` :
                                        ele.email.addr,
                                    uuid: uuidv4(),
                                })
                            })

                        } else if (taget.target) {
                            _address.push({
                                right: taget.right,
                                d: taget.target.d,
                                type: taget.target.type,
                                address: taget.target.email.addr,
                                label: taget.right === ZmSetting.SEND_ON_BEHALF_OF ?
                                    `${i18n.t('zimbra.zmMsg.onBehalfOfMidLabel')} ${taget.target.email.addr}` :
                                    taget.target.email.addr,
                                uuid: uuidv4(),
                            })
                        }
                    })
                }
                // console.log(_.orderBy(_address, "label", "asc"));
                commit("SET_FROM__ADDRESS", _address)
                commit("SET_ALL_IDENTITIES", listAccount);
                commit("SET_LIST_PERSONAL", listPersonal);
                commit("SET_PRIMARY_ACCOUNT", primaryAccount);
                const responseDataSource = response["dataSources"];
                if (responseDataSource !== "") {
                    const keys = Object.keys(responseDataSource);
                    const _list = []
                    keys.forEach(key => {
                        const _temp = Array.isArray(responseDataSource[key]) ?
                            responseDataSource[key] : [responseDataSource[key]]
                        if (key === ZmSetting.POP3) {
                            _temp.forEach(item => {
                                _list.push({
                                    ...item,
                                    accountType: ZmSetting.POP3
                                })
                            })
                        } else if (key === ZmSetting.IMAP) {
                            _temp.forEach(item => {
                                _list.push({
                                    ...item,
                                    accountType: ZmSetting.IMAP
                                })
                            })
                        }
                    });
                    commit(`SET_EXTERNAL_ACCOUNT`, _list);
                }
                return response;
            } catch (error) {
                console.log(error);
            }
        },
        async createPersonal({ commit }, data) {
            try {
                const response = await AccountService.createPersonalRequest(
                    data
                );
                const responseJson = response.data["soap:Envelope"]["soap:Body"]
                    .CreateIdentityResponse.identity;
                if (responseJson) {
                    const objResponse = {
                        ...responseJson,
                        email: data.zimbraDisplayEmail
                    };
                    commit("ADD_PERSONAL", objResponse);
                }
                return response;
            } catch (error) {
                console.log(error);
            }
        },
        async modifyPersonal({ commit }, data) {
            try {
                const response = await AccountService.modifyPersonalRequest(
                    data
                );
                const responseJson = response.data["soap:Envelope"]["soap:Body"];
                if (responseJson["ModifyIdentityResponse"]) {
                    commit("EDIT_PERSONAL", data);
                }
                return response;
            } catch (error) {
                console.log(error);
            }
        },
        async deletePersonal({ commit }, data) {
            try {
                const response = await AccountService.deletePersonalRequest(
                    data
                );
                const responseJson = response.data["soap:Envelope"]["soap:Body"];
                if (responseJson) {
                    commit("DELETE_PERSONAL", data);
                }
                return response;
            } catch (error) {
                console.log(error);
            }
        },
        async createdExteral({ commit }, formData) {
            try {
                const resp = await AccountService.createDataSourceRequest(formData)
                const respJson = CommonUtils.methods.getResponseBody(resp)
                const dataSources = respJson["CreateDataSourceResponse"]
                if (dataSources) {
                    const _type = formData.accountType
                    const _id = dataSources[_type]["id"]
                    commit("ADD_EXTERNAL", { ...formData, id: _id })
                }
                return resp
            } catch (error) {
                console.log(error);
            }
        },
        async modifyExternal({ commit }, formData) {
            try {
                const resp = await AccountService.modifyDataSourceRequest(formData)
                const respJson = CommonUtils.methods.getResponseBody(resp)
                const dataSources = respJson["ModifyDataSourceResponse"]
                if (dataSources) {
                    commit("EDIT_EXTERNAL", formData)
                }
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async deleteExternal({ commit }, formData) {
            try {
                const resp = await AccountService.deleteDataSourceRequest(formData)
                const respJson = CommonUtils.methods.getResponseBody(resp)
                const dataSources = respJson["DeleteDataSourceResponse"]
                if (dataSources) {
                    commit("DELETE_EXTERNAL", formData)
                }
                return resp
            } catch (error) {
                console.log(error)
            }
        }
    }
};
export default accountModule;
